@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Anek+Bangla:wght@100..800&display=swap");

body {
  font-family: "Anek Bangla", "Montserrat";
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar:horizontal {
  height: 8px;
}

::-webkit-scrollbar-thumb {
  @apply bg-[#F15625] rounded-xl;
}

.active {
  color: #f15625;
}
